import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import { Link } from 'react-router-dom';
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ProductCarousel from '../components/ProductCarousel';
import Meta from '../components/Meta';

import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from '../slices/authSlice';
import { useEffect } from 'react';
import { useLoginMutation } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';
import NavbarCategory from '../components/NavbarCategory';
const HomeScreen = () => {
  const { pageNumber, keyword, category } = useParams();

  const { data, isLoading, error } = useGetProductsQuery({
    keyword,
    category,
    pageNumber,
  });

  const [login, { isLoadingUser }] = useLoginMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!userInfo) {
      async function autoLogin() {
        try {
          const res = await login({
            email: '88@gmail.com',
            password: '88hao',
          }).unwrap();
          dispatch(setCredentials({ ...res }));
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
      }
      autoLogin();
    }
  }, []);

  return (
    <>
      {!keyword ? (
        <ProductCarousel />
      ) : (
        <Link to='/' className='btn btn-light mb-4'>
          {process.env.REACT_APP_CHINESE ? '返回' : 'Go Back'}
        </Link>
      )}
      {isLoading || isLoadingUser ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta title='e-commerce' />
          <h1>
            {process.env.REACT_APP_CHINESE ? '最新商品' : 'Latest Products'}
          </h1>
          <Row>
            <NavbarCategory
              category={category}
              pages={data.pages}
              page={data.page}
              keyword={keyword ? keyword : ''}
            />
            {data.products.map((product) => (
              <Col key={product._id} xs={6} sm={6} md={6} lg={4} xl={3}>
                <Product product={product} />
              </Col>
            ))}
          </Row>
          <Paginate
            pages={data.pages}
            page={data.page}
            category={category ? category : ''}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}
    </>
  );
};

export default HomeScreen;
